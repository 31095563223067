@import "./App.scss";

$color1: #FFB401;
$color2: #D81D1F;
$color3: #2D4059;

.timeline-container{
    margin-top: 100px;
}

.main-timeline{
    overflow: hidden;
    position: relative;
}
.main-timeline .timeline{ position: relative; }
 
.main-timeline .timeline:before,
.main-timeline .timeline:after{
    content: "";
    display: block;
    width: 100%;
    clear: both;
}
.main-timeline .timeline:before{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 3px solid #fff;
    background: $color1;
    box-sizing: content-box;
    margin: auto;
    position: absolute;
    top: 0;
    left: 2%;
    bottom: 0;
    right: 0;
    z-index: 2;
}
.main-timeline .timeline-icon{
    width: 24px;
    height: 100%;
    border-radius: 0 12px 12px 0;
    background: $color1;
    position: absolute;
    top: 0;
    left: 51%;
    z-index: 1;
    transform: translateX(-50%);
}
.main-timeline .timeline-icon:before{
    content: "";
    width: 47px;
    height: 4px;
    background: $color1;
    margin: auto 0;
    position: absolute;
    top: 0;
    left: 24px;
    bottom: 0;
}
.main-timeline .timeline-icon:after{
    content: "";
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: $color1;
    margin: auto 0;
    position: absolute;
    top: 0;
    left: 70px;
    bottom: 0;
}
.main-timeline .timeline-content{
    width: 50%;
    padding: 60px 40px 60px 0px;
    // height of timeline content
}
.main-timeline .title{
    font-family: $heading-font;
    font-size: 30px;
    // font-weight: bold;
    color: $color1;
    text-align: center;
    margin: 0 0 20px 0;
}
.main-timeline .description{
    font-size: 16px;
    // color: #959595;
    line-height: 1.8em;
    
    margin: 0;
}
.main-timeline .icon{
    width: 50%;
    height: auto;
    padding-left: 100px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
}
.main-timeline .icon .initial{
    font-family: $heading-font;
    display: inline-block;
    width: 85px;
    height: 85px;
    line-height: 87px;
    border-radius: 50%;
    background: $color1;
    font-size: 44px;
    color: #fff;
    text-align: center;
    margin-right: 20px;
}
.main-timeline .year{
    font-family: $heading-font;
    display: inline-block;
    font-size: 25px;
    // font-weight: bold;
    line-height: 36px;
    color: #797979;
    position: relative;
    top: -6px;
}
.main-timeline .timeline:nth-child(2n):before{ left: -2%; }
.main-timeline .timeline:nth-child(2n) .timeline-icon{
    border-radius: 12px 0 0 12px;
    left: 49%;
}
.main-timeline .timeline:nth-child(2n) .timeline-icon:before{ left: -47px; }
.main-timeline .timeline:nth-child(2n) .timeline-icon:after{ left: -62px; }
.main-timeline .timeline:nth-child(2n) .timeline-content{
    float: right;
    padding: 60px 0px 60px 40px;
    // height of timeline content
}
.main-timeline .timeline:nth-child(2n) .icon{
    padding: 0 100px 0 0;
    text-align: right;
    right: auto;
    left: 0;
}
.main-timeline .timeline:nth-child(2n) .icon .initial{
    float: right;
    margin: 0 0 0 20px;
}
.main-timeline .timeline:nth-child(2n) .year{ top: 24px; }
.main-timeline .timeline:nth-child(2n):before,
.main-timeline .timeline:nth-child(2n) .timeline-icon,
.main-timeline .timeline:nth-child(2n) .timeline-icon:before,
.main-timeline .timeline:nth-child(2n) .timeline-icon:after,
.main-timeline .timeline:nth-child(2n) .icon .initial{ background: $color2; }
.main-timeline .timeline:nth-child(2n) .title{ color: $color2; }
.main-timeline .timeline:nth-child(3n):before,
.main-timeline .timeline:nth-child(3n) .timeline-icon,
.main-timeline .timeline:nth-child(3n) .timeline-icon:before,
.main-timeline .timeline:nth-child(3n) .timeline-icon:after,
.main-timeline .timeline:nth-child(3n) .icon .initial{ background: $color3; }
.main-timeline .timeline:nth-child(3n) .title{ color: $color3; }
.main-timeline .timeline:nth-child(4n):before,
.main-timeline .timeline:nth-child(4n) .timeline-icon,
.main-timeline .timeline:nth-child(4n) .timeline-icon:before,
.main-timeline .timeline:nth-child(4n) .timeline-icon:after,
.main-timeline .timeline:nth-child(4n) .icon .initial{ background: #a3ba74; }
.main-timeline .timeline:nth-child(4n) .title{ color: #a3ba74; }
@media only screen and (max-width: 767px){
    .main-timeline .timeline:before,
    .main-timeline .timeline:nth-child(2n):before{
        margin: 0 auto;
        top: 4px;
        left: 0;
        right: 0;
    }
    .main-timeline .timeline-icon,
    .main-timeline .timeline:nth-child(2n) .timeline-icon{
        width: 100%;
        height: 24px;
        border-radius: 0 0 12px 12px;
        top: 0;
        left: 0;
        transform: translateX(0);
    }
    .main-timeline .timeline-icon:before,
    .main-timeline .timeline:nth-child(2n) .timeline-icon:before{
        width: 4px;
        height: 20px;
        margin: 0 auto;
        top: auto;
        left: 0;
        bottom: -20px;
        right: 0;
    }
    .main-timeline .timeline-icon:after,
    .main-timeline .timeline:nth-child(2n) .timeline-icon:after{
        margin: 0 auto;
        top: auto;
        left: 0;
        bottom: -32px;
        right: 0;
    }
    .main-timeline .timeline-content,
    .main-timeline .timeline:nth-child(2n) .timeline-content{
        float: none;
        width: 100%;
        padding: 140px 20px 30px 20px;
        text-align: center;
    }
    .main-timeline .icon,
    .main-timeline .timeline:nth-child(2n) .icon{
        width: 100%;
        text-align: center;
        padding: 0;
        top: 55px;
        right: 0;
        transform: translateY(0);
    }
    .main-timeline .icon i,
    .main-timeline .timeline:nth-child(2n) .icon .initial{
        float: none;
        // width: 70px;
        // height: 70px;
        // line-height: 74px;
        // font-size: 44px;
        margin: 0 20px 0 0;

        //
        display: inline-block;
        width: 85px;
        height: 85px;
        line-height: 87px;
        border-radius: 50%;
        font-size: 44px;
        color: #fff;
        text-align: center;
    }
    .main-timeline .timeline:nth-child(2n) .year{ top: -6px; }
}

@media (max-width : 480px) {
    .timeline-content{
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .description{
        text-align: left;
    }
}