@import url('https://fonts.googleapis.com/css?family=Muli&display=swap');
@import url('https://fonts.googleapis.com/css?family=Merriweather&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');

$primary-color: #2D4059;
$primary-dark-accent: #0B1D35;
$secondary-color: #FFB401;
$light-color: #F6F6F6;
$bright-color: #D81D1F;
$black-secondary: #343434;

$heading-font: 'Oswald', sans-serif;; 
$heading-spacing: .06em;

// $secondary-font: 'Merriweather', sans-serif;
$secondary-font: 'Open Sans', sans-serif;
$large-font: 30px;
$medium-font: 25px;

$navbar-height: 120px;
$mobile-navbar-height: 80px;
// base styles


body{
  background-color: $light-color !important;
}

h1{
  font-weight: 400 !important;
  letter-spacing: $heading-spacing;
}

h1, h2, h3, h4, h5, h6  {
  font-family: $heading-font;
  font-weight: 400 !important;
  // letter-spacing: $heading-spacing;

}

h3, h4, h5, h6{
  font-weight: 400 !important;
}

.jumbo{
  font-size: 70px;
}

.light-font{
  color: $light-color;
}

p{
  font-family: $secondary-font
}

a { 
  color: inherit !important; 
  text-decoration: none !important;
} 

img{
  max-width: 100vw;
  max-height: 100%;
}

.medium-spacing{
  // line-height: 38px;
  line-height: 1.8em;
}




// hero

.hero{
  margin-bottom: 55px;
}

.banner{
  position: relative;
  z-index: 50;
  width: 100%;
  height: 200px;
  background-color: $bright-color;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: $heading-font;
  font-size: 155px;
  // padding-right: 45px;
  color: $light-color;
}

.hero-buffer{
  height: 13vh;
}

.hero-diamond{
  z-index: 1;
  display: flex;
  position: relative;
  top: -100px;
}

.left-triangle{
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 55%);
  height: 690px;
  width: 35%;
  display: flex;
  justify-content: flex-end;
  background-color: $secondary-color;
}

.left-triangle img{
  margin-top: 100px;
  position: relative;
  left: 40px;
  height: 65%;
}

.right-triangle{

  clip-path: polygon(0 0, 0% 100%, 100% 0);
  padding-right: 200px;
  height: 690px;
  flex-grow: 1;
  background-color: $primary-color;
}

.slogan{
  margin-top: 100px;
  padding-top: 20px;
  // padding-left: 30px;
  padding-right: 20px;
  font-weight: 100;
  font-family: $heading-font;
  font-size: 30px !important;
  color: $light-color;
  letter-spacing: .02em !important;
  word-spacing: .28em;
}

.divider{
  width: 162px;
  height: 1px;
  margin-top: 18px;
  // margin-left: 30px;
  margin-right: 20px;
  background-color: $light-color;
}

.hero-navigation{
  list-style: none;
  margin-top: 50px;
  font-family: $heading-font;
  font-size: $medium-font;
  font-weight: 100;
  color: $light-color;
  padding: 0px 20px 0px 0px;
}

.hero-navigation li{
  padding-bottom: 20px;
}

.hero-navigation li:hover{
  cursor: pointer;
}

// navbar

.navbar-buffer{
  height: $navbar-height;
  width: 100%;
}

.navigation-bar{
  height: $navbar-height;
  width: 100%;
  background-color:$light-color;
  position: fixed;
  z-index: 100;
  // top: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: $heading-font;
}

.navigation-routes{
  display: flex;
}

.navigation-routes .route{
  padding: 0px 30px;
  font-size: $medium-font;
}

.route.active {
  color: $secondary-color;
}


.mobile-nav{
  z-index: 100;
  margin-top: $navbar-height;
  position: fixed;
  width: 100vw;
  background-color: $light-color;
  transition: height 700ms; 
  overflow: hidden;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-navigation-routes .route{
  padding: 0px 0px 35px 0px;
  font-size: $medium-font;
  font-family: $heading-font;
}
// icon 

.bar{
  width: 245px;
  height: 45px;
  background-color: $bright-color;
  display: flex;
  align-items: self;
  justify-content: flex-end; 
  font-size: 38px;
  color: $light-color;
}
// section styles

section{
  width: 100%;
}

.mission{
  min-height: 930px;
  background-color: $secondary-color;
  margin-bottom: -100px;
}

.angled-top{
  clip-path: polygon(0 100px, 100% 0, 100% 100%, 0% 100%);
}

.icon-section{
  // height:1760px;
  background-color:#464641;
}

.icon-section .container{
  padding-top: 250px;
  padding-bottom: 150px;
}

.icon-row{
  margin-bottom: 100px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.curved-bottom{
  border-radius: 0px 0px 15vw 0px;
}



.initiatives-section{
  min-height: 800px;
  background-color: $secondary-color;
}

.community-section{
  position: absolute;
  z-index: 5;
  height: 800px;
  width: 100vw;
  background-color: $secondary-color;
}

.white-fill{
  border-radius: 15vw 0px 0px 0px;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: $light-color;
  z-index: 1;
}

.light{
  background: $light-color;
}

.half-split .container{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.half-split{
  display: flex;
  align-items: center;
}

section .circle{
  display: block;
  // width: 430px;
  // height: 430px;
  height: 300px;
  width: 300px;
  border-radius: 50%;
}

.circle-small{
  height: 250px;
  width: 250px;
  border-radius: 50%;
  background-color: $secondary-color;
}

.service-icon-block{
  height: 100%;
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}



// .circle-medium{
//   height: 100%;
//   width: 100%;
//   border-radius: 50%;
//   background-color: $secondary-color;
// }


.icon-container{
  min-width: 33%;
}

section .container .text-block{
  width: 66%;
  // padding-right: 50px
}

.text-container{
  width: 66%;
}

.text-container h1{
  margin-bottom: 30px;
}

.text-container h3{
  margin-bottom: 20px;
}

.text-container p{
  font-size: 18px;
}

.text-block p{
  // line-height: 38px;
  line-height: 2em;
  font-size: 18px;
  margin: 45px 0px;
}

.link-arrow{
  float: right;
  font-family: $heading-font;
  font-size: 30px;
  font-weight: 300;
}

.white-body{
  position: relative;
  z-index: 50;
  padding-bottom: 100px;
  padding-top: 200px;
  background-color: $light-color;
  min-height: 600px;
  border-radius: 10vw;
}

//homepage


//contact
.contact{
  padding-top: 0px !important;
}



.team-photo-container{
  flex-basis: 350px;
}

.team-photo{
  width: 350px;
  height: 350px;
  // width: 100%;
  // height: 100%;
  object-fit: cover;
  border-radius: 50%;
  border: 20px solid $secondary-color;
  margin-right: 60px;
}



.contact-info{
  // flex-basis: 625px;
  // margin-left: 30px;
  flex-grow: 2;
}

.contact-info h1{
  margin-bottom: 40px;
}

.contact-info > p{
  margin-bottom: 40px;
}

// team-page

.team-container{
  position: relative;
  padding: 200px 0px 100px 0px ;

}

.member-circle{
  display: flex;
  justify-content: center;
  align-items: center;
  
  height: 350px;
  width: 350px;
  border-radius: 50%;
  background-color: $secondary-color;
}

.member-container{
  margin-top: 5px;
  // width: 50%;
  // margin-right: 10px;
  
}

.member-frame{
  // background-color: white;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  height: 90%;
  width: 90%;
  border-radius: 50%;
}

.bio-container{
  width: 60%;
}

.bio-container h1{
  margin-bottom: 40px;
}

.read-more{
  font-style: italic;
  font-weight: bold;
  cursor: pointer;
}

.social-row{
  width: 100%;
  display:flex;
  justify-content: flex-end;
}

.social-icon{
  width: 40px;
  height: 40px;
  margin-left: 10px;
}
// footer

.footer-container{
  padding-top: 380px;
  text-align: center;
}

footer{
  background-color: $black-secondary;
  font-family: $heading-font;
  width: 100%;
  height: 700px;
  display: flex;
  text-align: center;
  margin-top: -220px;
  margin-bottom: -1px;
  justify-content: center;
  color: $light-color;
  border: none;
}


.footer-row{
  margin: 50px 0px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.footer-link{
  font-size: 30px;
  font-weight: 100;
  min-width: 16vw;
  max-width: 50vw;
}

.dot{
  display: block;
  height: 10px;
  width: 10px;
  background-color: $light-color;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 60px;
}


.base{
  width: 100%;
  border: none;
  color: $light-color;
  font-family: $heading-font;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  font-weight: 100;
  height: 85px;
  z-index: 10;
  background-color: #292929;
}

.base p{
  margin-bottom: 0px;
  opacity: .5;
  font-size: 90%;
  margin: 0px 60px;
  width: 400px;
}

footer h1{
  font-size: 40px !important;
}

// training page

.testimonials{
  // display: flex;
  align-items: center ;
  flex-wrap: wrap;
  justify-content: space-between;
}

//call to action

.call-to-action{
  text-align: center;
  margin: 160px 0px 120px 0px;
}

.call-to-action h1{
  margin: 0px 0px 60px 0px;
}

.call-to-action p{
  text-align: left;
  font-size: 18px;
  margin: 60px 0px 40px 0px;
}

.call-to-action .button{
  background-color: $secondary-color;
  box-shadow: 0 2px 4px -1px rgba(0,0,0,0.2);
  width: 200px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  font-weight: 400;
  font-size: 24px;
  font-family: $heading-font;
  margin: 0px auto 0px auto;
}

// all pages

.body-heading{
  margin-bottom: 50px;
}

  /*==========  Mobile SIzes  ==========*/

    /* Custom, iPhone Retina */ 
    @media (min-width : 320px) {

    }

    /* Extra Small Devices, Phones */ 
    @media (min-width : 480px) {

    }

    /* Small Devices, Tablets */
    @media (min-width : 768px) {
  
      .contact{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 200px;
      }
    }

    /* Medium Devices, Desktops */
    @media (min-width : 992px) {
      // nav responsive
      h1{
        font-size: 70px !important;
      }

      .bio-container h1{
        font-size: 50px !important;
      }

      #menuToggle{
        display: none;
      }
  
      
    }

    /* Large Devices, Wide Screens */
    @media (min-width : 1200px) {

    }

    /*==========  Non-Mobile First Method  ==========*/

    /* Large Devices, Wide Screens */
    @media (max-width : 1200px) {
      // hero
    
      .left-triangle img{
        position: relative;
        left: 40px;
        height: 40%;
      }
    }
    @media (min-width : 992px) {
      .testimonials{
        display: flex;
      }
      .highlight-link{
        color: $light-color !important;
      }
      .highlight-background{
        background-color: $secondary-color;
      }

      .mobile-nav{
        display: none;
      }

      .main-timeline{
        margin-bottom: 100px;
      }
      
      
    }

    //custom footer mq
    @media (max-width: 772px){
      .footer-link{
        min-width: 100vw;
        width: 100vw;
      }
    }

    @media (max-width : 992px) and (min-width : 480px) { 
      .tablet-only-p-t{
        padding-top: 150px !important;
      }
    }

    /* Medium Devices, Desktops */
    @media (max-width : 992px) { 



      .tablet-padding-top-none{
        padding-top: 0px !important;
      }
      .tablet-margin-top-none{
        margin-top: 0px !important;
      }

      .tablet-centered{
        width: 100%;
        display: flex;
        justify-content: center;
      }

      .shrink{
        width: 200px !important;
        height: 200px !important;
      }

      //general

      .tablet-bottom-buffer{
        margin-bottom: 40px;
      }

      .tablet-bottom-buffer-padding{
        padding-bottom: 80px;
      }


      //container
      .white-body{
        padding-top: 50px;
      }

      //nav responsive
      .tablet-none{
        display: none !important;
      }
      .navigation-bar{
        height: 120px;
      }

      .navigation-routes{
        display: none;
      }

      section .container .text-block{
        width: 100%;
        // padding-right: 50px
      }

      section .container{
        padding-top: 50px;
        padding-bottom: 50px;
      }

      .angled-top .container{
        padding-top: 50px;
        padding-bottom: 50px;
      }

      .text-container{
        width: 100%;
      }
      
      .icon-container{
        width: 100%;
        display: flex;
        justify-content: center;
      }

      .member-container{
        width: 100%;
        display: flex;
        justify-content: center;
      }

      .icon-row{
        margin-bottom: 50px;
      }

      .bio-container h1{
        margin-top: 40px;
      }
      //

      // contact 
      // .contact{
      //   width: 100%;
      //   display: flex;
      //   justify-content: center;
      // }

      // hero
      .hero{
        margin-bottom: -100px;
      }

      .hero-diamond{
        flex-direction: column-reverse;
      }
      .right-triangle{
        position: absolute;
        clip-path: polygon(0 0, 100% 0, 100% 30%, 0% 100%);
        width: 100%;
        padding-right: 0px; 
      }
      .left-triangle{
        clip-path: none;
        width: 100%;
        align-items: flex-end;
      }
      .left-triangle img{
        position: static;
        height: 50%;
      }
      .hero-buffer{
        height: 120px; 
      }
      .slogan{
        font-size: $medium-font;
      }

      //homepage

      .tablet-reverse{
        flex-direction: column-reverse;
      }



      //team page
      .bio-container{
        width: 100%;
      }

      .bio-container p{
        margin-bottom: 0px;
      }

      .team-container{
        padding: 0px;
      }
    }

    // research page

    .pub-row{
      padding: 50px 0px;
      display: flex;
      border-bottom:1px solid #BCBCBC;
    }



    .pub-list > div:last-child{
      border-bottom: none;
    }

    .pub-year{
      margin-right: 30px;
      display: flex;
      align-items: center;
    }

   

    .year-dot{
      color: $light-color;
      line-height: 0px;
      border-radius: 50%;
      height: 5em;
      min-height: 5em;
      min-width: 5em;
      width: 5em;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $secondary-color;
    }

    .year-dot h4{
      position: relative;
      top: 2px;
    }

    /* Small Devices, Tablets */
    @media (max-width : 768px) {
            //contact
            .team-photo-container{
              display: flex;
              justify-content: center;
            }

            .team-photo{
              margin-right: 0px;
            }
            

            //footer
            .footer-row{
              flex-wrap: wrap;
            }

            .footer-link .dot{
              display: none;
            }

            .footer-link{
              width: 100vh;
            }

            .footer-container{
              padding-top: 300px;
            }

          .white-body{
            padding-top: 0px;
            padding-bottom: 50px;
          }

    
            
    }

    /* Extra Small Devices, Phones */ 
    @media (max-width : 480px) {
      // font
      .text-container p{
        font-size: 16px;
      }
      
      .text-block p{
        // line-height: 38px;
        line-height: 2em;
        font-size: 16px;
      }

      // nav


      .initiatives-section{
        min-height: 0px;
      }

      .navigation-bar{
        height: $mobile-navbar-height;
      }

      .navbar-buffer{
        height: $mobile-navbar-height;
      }

      .mobile-nav{
        margin-top: $mobile-navbar-height;
      }

      .bar{
        width: calc(50vw + 50px);
      }

      .circle.light{
        height: 300px;
        width: 300px;
      }

      .member-circle{
        height: 300px;
        width: 300px;
      }

      .medium-spacing{
        line-height: 30px;
      }


      .white-body{
        padding-top: 0px;
      }

    
    
      //hero
      .banner{
        height: 100px;
        justify-content: flex-end;
        align-items: center;
        font-size: 75px;
        padding-right: 0px;
        margin-top: 0px;
      }
      .hero-buffer{
        height: $mobile-navbar-height;
      }

      .right-triangle{
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 40%);

      }
      .left-triangle img{
        height: 35%;
      }
      .left-triangle{
        align-items: flex-end;
        justify-content: flex-start;
      }
      .hero-navigation{
        margin-top: 35px;
        float: right;
        text-align: end;
      }
      .slogan{
        text-align: end;
        font-size: 20px;
      }
      .divider{
        float: right;
        height: 1px;
        width: calc(100% - 60px);
        opacity: .5;
        position: absolute;
      }

      // sections
      .angled-top{
        clip-path: none;
      }
      
      .timeline-header{
        display: none !important;
      }

    }

    /* Custom, iPhone Retina */ 
    @media (max-width : 320px) {
      
    }



// animations

.fade-in {
  opacity: .8;
  animation:ease-in 1;
  animation-name: fade-in;
  animation-duration: 1s;
}

@keyframes fade-in {
  0% { opacity: 0; }
  100% { opacity: .8; }
}


.top {
	animation-name: fade;
	animation-timing-function: ease-in-out;
	animation-iteration-count: infinite;
	animation-duration: 3s;
	animation-direction: alternate;
}

// .bottom {
// 	animation-name: fade-reverse;
// 	animation-timing-function: ease-in-out;
// 	animation-iteration-count: infinite;
// 	animation-duration: 3;
// 	animation-direction: alternate;
// }


@keyframes fade {
	0% {
		opacity: 1;
	}
	25% {
		opacity: .5;
	}
	75% {
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}

// @keyframes fade-reverse {
// 	0% {
// 		opacity: 0;
// 	}
// 	25% {
// 		opacity: 0;
// 	}
// 	75% {
// 		opacity: .5;
// 	}
// 	100% {
// 		opacity: 1;
// 	}
// }